<template>
  <div class="pa-3">
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <h2 class="title-style">
          Organization Details
        </h2>
      </v-col>
    </v-row>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container fluid>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="formData.name"
                    label="Organization Name*"
                    hint="Name for your reference - 'DBSystems'."
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="formData.organizationType"
                    menu-props="auto"
                    :items="organizationsList"
                    label="Organization Type*"
                    item-text="id"
                    item-value="value"
                    prepend-icon="mdi-domain"
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <address-form
                    ref="addressForm"
                    @addressEntered="assiginAddress($event)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="formData.phoneNumber"
                    label="Phone-Number"
                    type="number"
                  />
                </v-col>
                <v-autocomplete
                  v-model="formData.officeTimeZone"
                  menu-props="auto"
                  :items="timeZonesByName"
                  label="Time Zone*"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="formData.alias"
                    label="Alias"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="formData.externalId"
                    label="Organization-Id"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-form>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#000"
              @click="submit()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
      <br>
    </v-card>
  </div>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddressForm from 'src/views/dashboard/component/AddressForm';
  import global from 'src/mixins/global';

  export default {
    name: 'EditOrganization',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    filters: {
      firstLetterCapital (val) {
        if (val) {
          return val[0].toUpperCase() + val.slice(1);
        }
      },
      statusUpdate (status) {
        if (status === 'active') {
          return 'Active*';
        } else if (status === 'trial') {
          return 'Trial*';
        } else {
          return 'Expired';
        }
      },
    },
        mixins: [global],
    data () {
      return {
        valid: false,
        loading: false,
        formData: {
          name: '',
          organizationType: '',
          phoneNumber: null,
          officeTimeZone: '',
          alias: null,
          externalId: '',
          address: {},
        },
        organizationsList: [{ id: 'Church', value: 'church' }, { id: 'Shopping Centre', value: 'shoppingCentre' }, { id: 'Construction', value: 'construction' }, { id: 'Restaurants', value: 'restaurants' }, { id: 'Dental', value: 'dental' }],
      };
    },
    computed: {
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
      isOrganizationDetailsFetched () {
        if (Object.keys(this.organizationDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      await this.FetchOrganizationDetails();
      this.fillData();
      this.getAllTimeZone();
      this.loading = false;
    },
    methods: {
      fillData () {
        this.formData.name = this.organizationDetails.name;
        this.formData.organizationType = this.organizationDetails.type;
        this.formData.phoneNumber = this.organizationDetails.phone_number;
        this.formData.officeTimeZone = this.organizationDetails.office_timezone;
        this.formData.alias = this.organizationDetails.alias;
        this.formData.externalId = this.organizationDetails.external_id;
        if (this.organizationDetails.address !== null) {
          this.$refs.addressForm.fillData(this.organizationDetails.address);
        }
      },
      async submit () {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please contact support for updating your organization details.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
      },
      assiginAddress (address) {
        this.formData.address = address;
      },
      async FetchOrganizationDetails () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationDetails').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones');
      },
      getColor (status) {
        if (status === 'active') return '#2E7D32';
        else if (status === 'expired') return '#C62828'; else return '#EF6C00';
      },
    },
  };
</script>
<style scoped>
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.title-style {
  color: #37474F;
}
/* .my-base-vcard-style {
  background-color: #CFD8DC !important;
} */
.my-vcard-style {
  /* margin-right: 30px;
  margin-left: 30px; */
  /* background-color: #ECEFF1; */
  border-radius: 25px;
  box-shadow: none !important;
}
.font-my-style {
  font-weight: bold;
  font-size: 17px;
  color: #37474F;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-bottom: 5px;
}
.my-status-style {
  font-weight: bold;
  font-size: 17px;
}
.subscription-details {
  border: 2px solid #CFD8DC;
  border-radius: 15px;
}
</style>
